import React from "react"

import Layout from "../components/Layout/"
import SEO from "../components/seo"
import { Box, Container } from "@material-ui/core"

const NotFoundPage = () => (
  <Layout transparentHeader>
    <SEO title="404: Not found" />
    <Container maxWidth="lg">
      <Box marginTop="9rem">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </Box>
    </Container>
  </Layout>
)

export default NotFoundPage
